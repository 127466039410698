import React, { useState } from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Button, Typography } from "@mui/material";

const GoToTop = () => {
  const button = {
    position: "fixed",
    left: {
      xs: "85%",
      md: "85%",
      xl: "95%",
    },
    top: "95%",
    height: "20px",
    fontSize: "3rem",
    zIndex: "99",
    cursor: "pointer",
    color: "#ffb535",
  };

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Button id="go-to-top" sx={button} aria-label="go to top">
      <ArrowCircleUpIcon
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none", fontSize: "3rem" }}
      />
    </Button>
  );
};

export default GoToTop;
