import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import { useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";

import GoToTop from "./components/GoToTop";
import Cookie from "./components/Cookie";

const HomePage = lazy(() => import("./scenes/homePage"));
const ActualitePage = lazy(() => import("./scenes/actualitePage"));
const PreparationPage = lazy(() => import("./scenes/preparationPage"));
const LivreDorPage = lazy(() => import("./scenes/livreDorPage"));
const PolitiqueDeConfidentialitePage = lazy(() =>
  import("./scenes/politiqueDeConfidentialitePage")
);
const MentionsLegalesPage = lazy(() => import("./scenes/mentionsLegalesPage"));
const MusiquePage = lazy(() => import("./scenes/musiquePage"));
const SterilisationPage = lazy(() => import("./scenes/sterilisationPage"));
const CouleurPage = lazy(() => import("./scenes/couleurPage"));
const DangerPage = lazy(() => import("./scenes/dangerPage"));
const CastrationPage = lazy(() => import("./scenes/castrationPage"));
const AlimentationPage = lazy(() => import("./scenes/AlimentationPage"));
const EntretienPage = lazy(() => import("./scenes/entretienPage"));
const VermifugeNaturelPage = lazy(() =>
  import("./scenes/vermifugeNaturelPage")
);
const VermifugeChimiquePage = lazy(() =>
  import("./scenes/vermifugeChimiquePage")
);
const DetailActualitePage = lazy(() => import("./scenes/detailActualitePage"));
const DetailChatonPage = lazy(() => import("./scenes/detailChatonPage"));
const DetailChatFemellePage = lazy(() =>
  import("./scenes/detailChatFemellePage")
);
const DetailChatMalePage = lazy(() => import("./scenes/detailChatMalePage"));
const ChatonPage = lazy(() => import("./scenes/chatonPage"));
const FemellePage = lazy(() => import("./scenes/femellePage"));
const AddActualitePage = lazy(() => import("./scenes/addActualitePage"));
const AddUserPage = lazy(() => import("./scenes/addUserPage"));
const AddChatPage = lazy(() => import("./scenes/addChatPage"));
const AdminUsersPage = lazy(() => import("./scenes/adminUsersPage"));
const AdminMalesPage = lazy(() => import("./scenes/adminMalesPage"));
const AdminFemellesPage = lazy(() => import("./scenes/adminFemellesPage"));
const AdminChatonsPage = lazy(() => import("./scenes/adminChatonsPage"));
const ContactPage = lazy(() => import("./scenes/contactPage"));
const AdminActualitesPage = lazy(() => import("./scenes/adminActualitesPage"));
const TarifPage = lazy(() => import("./scenes/tarifPage"));
const MalePage = lazy(() => import("./scenes/malePage"));
const LoginPage = lazy(() => import("./scenes/loginPage"));
const TemperamentPage = lazy(() => import("./scenes/temperamentPage"));
const TestPage = lazy(() => import("./scenes/TestPage"));

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));
  const changeHeight = () => {
    const box = document.querySelector(".App");
    const height = box.offsetHeight - 210;

    const el = document.getElementById("navbar-div");
    if (el) {
      el.style.height = height + "px";
    }
  };
  return (
    <div className="App" onWheel={() => changeHeight()}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<h1>&nbsp;</h1>}>
            <Routes>
            <Route path="/admin/test" element={<TestPage />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/actualites" element={<ActualitePage />} />
              <Route
                path="/actualites/:name"
                element={<DetailActualitePage />}
              />
              <Route path="/males" element={<MalePage />} />
              <Route path="/femelles" element={<FemellePage />} />
              <Route path="/chatons" element={<ChatonPage />} />
              <Route path="/males/:name" element={<DetailChatMalePage />} />
              <Route
                path="/femelles/:name"
                element={<DetailChatFemellePage />}
              />
              <Route path="/chatons/:name" element={<DetailChatonPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/tarifs-et-conditions" element={<TarifPage />} />
              <Route
                path="/conseil/preparer-arrivee-chaton"
                element={<PreparationPage />}
              />
               <Route
                path="/conseil/temperament"
                element={<TemperamentPage />}
              />
              <Route
                path="/conseil/vermifuge-naturel"
                element={<VermifugeNaturelPage />}
              />
              <Route
                path="/conseil/vermifuge-chimique"
                element={<VermifugeChimiquePage />}
              />
              <Route path="/conseil/entretien" element={<EntretienPage />} />
              <Route
                path="/conseil/alimentation"
                element={<AlimentationPage />}
              />
              <Route path="/conseil/castration" element={<CastrationPage />} />
              <Route path="/conseil/danger" element={<DangerPage />} />
              <Route path="/conseil/couleur" element={<CouleurPage />} />
              <Route
                path="/conseil/sterilisation"
                element={<SterilisationPage />}
              />
              <Route path="/conseil/musique" element={<MusiquePage />} />
              <Route path="/conseil" element={<Navigate to="/" />} />
              <Route
                path="/mentions-legales"
                element={<MentionsLegalesPage />}
              />
              <Route
                path="/politique-de-confidentialite"
                element={<PolitiqueDeConfidentialitePage />}
              />
              <Route path="/livre-dor" element={<LivreDorPage />} />
              <Route
                path="/admin/login"
                element={
                  isAuth ? <Navigate to="/admin/chatons" /> : <LoginPage />
                }
              />
              <Route
                path="/admin/users"
                element={
                  isAuth ? <AdminUsersPage /> : <Navigate to="/admin/login" />
                }
              />
              <Route
                path="/admin/users/add"
                element={
                  isAuth ? <AddUserPage /> : <Navigate to="/admin/login" />
                }
              />
              <Route
                path="/admin/chatons"
                element={
                  isAuth ? <AdminChatonsPage /> : <Navigate to="/admin/login" />
                }
              />
              <Route
                path="/admin/chats/add"
                element={
                  isAuth ? <AddChatPage /> : <Navigate to="/admin/login" />
                }
              />
              <Route
                path="/admin/males"
                element={
                  isAuth ? <AdminMalesPage /> : <Navigate to="/admin/login" />
                }
              />
              <Route
                path="/admin/femelles"
                element={
                  isAuth ? (
                    <AdminFemellesPage />
                  ) : (
                    <Navigate to="/admin/login" />
                  )
                }
              />
              <Route
                path="/admin/posts"
                element={
                  isAuth ? (
                    <AdminActualitesPage />
                  ) : (
                    <Navigate to="/admin/login" />
                  )
                }
              />
              <Route
                path="/admin/posts/add"
                element={
                  isAuth ? <AddActualitePage /> : <Navigate to="/admin/login" />
                }
              />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
      <Cookie />
      <GoToTop />
    </div>
  );
}

export default App;
