import { Link } from "@mui/material";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  const link = {
    color: "#E5B414",
    textDecoration:"none",
    "&:hover": {
      fontWeight: "bold",
    },
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      cookieName="myCookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{
        backgroundColor: "green",
        color: "white",
        fontSize: "13px",
      }}
      expires={150}
      enableDeclineButton
      declineButtonText="Refuser"
      declineButtonStyle={{ backgroundColor: "#E0E0E0", color: "#333333" }}
      ariaAcceptLabel="Accepter"
      ariaDeclineLabel="Refuser"
    >
      Nous utilisons des cookies pour comprendre comment vous interagissez avec
      notre site. En acceptant, vous consentez à notre utilisation de ces
      cookies.{" "}
      <Link href="/politique-de-confidentialite" sx={link}>
        Voir la politique de confidentialité
      </Link>
    </CookieConsent>
  );
};

export default Cookie;
